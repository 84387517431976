<script lang="ts">
    import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
    import { Provider as WireframeProvider } from "@evento/wireframe";
    import { Provider as EditorProvider } from "@evento/editor";
    import { Provider as ElearningClientProvider } from "@evento/elearning_client";

    const client = new QueryClient();
</script>

<template>
    <QueryClientProvider {client}>
        <WireframeProvider>
            <EditorProvider>
                <ElearningClientProvider>
                    <slot />
                </ElearningClientProvider>
            </EditorProvider>
        </WireframeProvider>
    </QueryClientProvider>
</template>
