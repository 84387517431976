import { Application } from "@evento/application/utils";
import { Layout, AuthLayout } from "@evento/elearning_client/layouts";

import { list } from "@evento/elearning_client/pages";
import Provider from "@base/scripts/StudyProvider.svelte";

import "./base.ts";

import "tailwindcss/tailwind.css";
import "@evento/wireframe/styles";
import "@evento/editor/styles";
import "@evento/elearning_client/styles";

const app = new Application()
    .setPages({
        default: list,
    })
    .setLayouts({
        default: [Provider, Layout],
        auth: [Provider, AuthLayout],
    })
    .setLayoutResolver(function (name, layouts, resolve) {
        if (name.includes("Auth/") && !name.includes("Profile")) {
            return resolve(layouts.auth);
        }

        return resolve(layouts.default);
    })
    .setColor("#324897")
    .setDebug(import.meta.env.VITE_DEBUG);

app.run();
